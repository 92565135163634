<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>品种管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <div v-auth="'plantVariety-add'">
        <el-button type="primary" size="small" @click="addPlantVariety"
          >新增品种</el-button
        >
        &nbsp;
      </div>
      <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
        v-loading="spinning"
        :data="data"
        row-key="id"
      >
        <el-table-column
            type="index"
            width="50">
        </el-table-column>
        <el-table-column prop="name" label="品种名称"> </el-table-column>
        <el-table-column prop="otherName" label="品种别名"> </el-table-column>
        <el-table-column prop="series" label="种子系列"> </el-table-column>
        <el-table-column prop="status" label="优良性别">
          <template slot-scope="scope">
            <span>{{ scope.row.status | statusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="plantWay" label="种植方式">
          <template slot-scope="scope">
            <span>{{ scope.row.plantWay | plantWayFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="imgPathAll" label="图片">
          <template slot-scope="scope">
            <img
              v-if="scope.row.imgPathAll"
              :src="scope.row.imgPathAll"
              style="padding: 5px; height: 80px; width: auto"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="200"
          label="操作"
          align="center"
          v-if="authVerify.verify('plantVariety-edit,plantVariety-delete')"
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit-outline"
              size="mini"
              v-auth="'plantVariety-edit'"
              @click="editPlantVariety(scope.row)"
              >编辑
            </el-button>
            <el-button
              icon="el-icon-delete"
              size="mini"
              v-auth="'plantVariety-delete'"
              @click="deletePlantVariety(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40]"
        :current-page="currentPage"
        :page-size="pageSize"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
        :total="totalCount"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
          title="新增品种"
          width="400"
          placement="right"
          :closable="false"
          :visible="addPlantVarietyVisible"
          @close="addPlantVarietyVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="plantVariety"
              ref="plantVarietyRefForm"
              :rules="plantVarietyRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="品种名称" prop="name">
                <a-input v-model="plantVariety.name" allowClear placeholder="请输入品种名称"/>
              </a-form-model-item>
              <a-form-model-item label="品种别名" prop="otherName">
                <a-input v-model="plantVariety.otherName" allowClear placeholder="请输入品种别名"/>
              </a-form-model-item>
              <a-form-model-item label="种子系列" prop="series">
                <a-input v-model="plantVariety.series" allowClear placeholder="请输入种子系列"/>
              </a-form-model-item>
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="plantVariety.remark" allowClear placeholder="请输入备注"/>
              </a-form-model-item>
              <a-form-model-item label="优良性别" prop="status">
                <a-select v-model="plantVariety.status" allowClear placeholder="请选择优良性别">
                  <a-select-option key="EXCELLENT"> 优 </a-select-option>
                  <a-select-option key="GOOD"> 良 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植方式" prop="plantWay">
                <a-select v-model="plantVariety.plantWay" allowClear placeholder="请选择种植方式">
                  <a-select-option key="MANUAL"> 人工 </a-select-option>
                  <a-select-option key="MACHINE"> 机器 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="照片" prop="imgPath">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUpload"
                  @change="imgHandleChange"
                >
                  <div v-if="!isShowImg">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="plantVariety.imgPathAll"
                      :src="plantVariety.imgPathAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addPlantVarietyVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddPlantVariety">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
          title="编辑品种"
          width="400"
          placement="right"
          :closable="false"
          :visible="editPlantVarietyVisible"
          @close="editPlantVarietyVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="plantVariety"
              ref="editPlantVarietyRefForm"
              :rules="plantVarietyRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="品种名称" prop="name">
                <a-input v-model="plantVariety.name" allowClear placeholder="请输入品种名称"/>
              </a-form-model-item>
              <a-form-model-item label="品种别名" prop="otherName">
                <a-input v-model="plantVariety.otherName" allowClear placeholder="请输入品种别名"/>
              </a-form-model-item>
              <a-form-model-item label="种子系列" prop="series">
                <a-input v-model="plantVariety.series" allowClear placeholder="请输入种子系列"/>
              </a-form-model-item>
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="plantVariety.remark" allowClear placeholder="请输入备注"/>
              </a-form-model-item>
              <a-form-model-item label="优良性别" prop="status">
                <a-select v-model="plantVariety.status" allowClear placeholder="请选择优良性别">
                  <a-select-option key="EXCELLENT"> 优 </a-select-option>
                  <a-select-option key="GOOD"> 良 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="种植方式" prop="plantWay">
                <a-select v-model="plantVariety.plantWay" allowClear placeholder="请选择种植方式">
                  <a-select-option key="MANUAL"> 人工 </a-select-option>
                  <a-select-option key="MACHINE"> 机器 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="照片" prop="imgPath">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUpload"
                  @change="imgHandleChange"
                >
                  <div v-if="!isShowImg">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="plantVariety.imgPathAll"
                      :src="plantVariety.imgPathAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editPlantVarietyVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditPlantVariety">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: "PlantVariety",
  data() {
    return {
      httpUploadSrc: this._axios_env.SERVER+`/file/img?access_token=${localStorage
        .getItem("access_token")
        .substring(6, localStorage.getItem("access_token").length)}`,
      addPlantVarietyVisible: false,
      editPlantVarietyVisible: false,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      spinning: true,
      data: [],
      plantVariety: {
        id: "",
        name: "",
        otherName: "",
          series: "",
        status: undefined,
        plantWay: undefined,
        imgPath: "",
        imgPathAll: "",
        remark: "",
      },
      plantVarietyRules: {
        name: [{ required: true, message: "请输入品种名称!", trigger: "blur" }],
        otherName: [
          { required: true, message: "请输入品种别名!", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择优良性别!", trigger: "blur" },
        ],
        plantWay: [
          { required: true, message: "请选择种植方式!", trigger: "blur" },
        ],
        imgPath: [{ required: true, message: "请上传照片!", trigger: "blur" }],
      },
    };
  },
  filters: {
    plantWayFilter: function (value) {
      if (!value) return "";
      if (value === "MANUAL") {
        return "人工";
      } else if (value === "MACHINE") {
        return "机器";
      }
    },
    statusFilter: function (value) {
      if (!value) return "";
      if (value === "EXCELLENT") {
        return "优";
      } else if (value === "GOOD") {
        return "良";
      }
    },
  },
  computed: {
    //判断是否显示Logo
    isShowImg() {
      if (this.plantVariety.imgPath === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.plantVariety.imgPath);
      }
    },
  },
  mounted() {
    this.getPlantVarietyData();
  },
  created() {},
  methods: {
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/gi;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error("请上传正确的图片格式！");
        return false;
      }
    },
    imgHandleChange({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.plantVariety.imgPathAll = file["response"].data.allPath;
        this.plantVariety.imgPath = file["response"].data.path;
      }
    },
    getPlantVarietyData() {
      this.http.plantVariety
        .getPlantVarietyListPage({
          size: this.pageSize,
          current: this.currentPage,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.data = response.data.data.records;
            this.totalCount = response.data.data.total;
            this.currentPage = response.data.data.current;
          }
          this.spinning = false;
        });
    },
    cleanPlantVariety() {
      this.plantVariety = {
        id: "",
        name: "",
        otherName: "",
          series: "",
        status: undefined,
        plantWay: undefined,
        imgPath: "",
        imgPathAll: "",
        remark: "",
      };
    },
    addPlantVariety() {
      this.addPlantVarietyVisible = true;
      this.cleanPlantVariety();
        this.$refs.plantVarietyRefForm.resetFields()
    },
    toAddPlantVariety() {
      this.$refs.plantVarietyRefForm.validate((valid) => {
        if (valid) {
          this.http.plantVariety
            .createdPlantVariety(this.plantVariety)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addPlantVarietyVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getPlantVarietyData();
            });
        }
      });
    },
    toEditPlantVariety() {
      this.$refs.editPlantVarietyRefForm.validate((valid) => {
        if (valid) {
          this.http.plantVariety
            .updatePlantVariety(this.plantVariety)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editPlantVarietyVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getPlantVarietyData();
            });
        }
      });
    },
    editPlantVariety(data) {
      this.cleanPlantVariety();
      this.plantVariety = {
        id: data.id,
        name: data.name,
        otherName: data.otherName,
          series: data.series,
        status: data.status,
        plantWay: data.plantWay,
        imgPath: data.imgPath,
        imgPathAll: data.imgPathAll,
        remark: data.remark,
      };
      this.editPlantVarietyVisible = true;
        this.$refs.editPlantVarietyRefForm.resetFields()
    },
    deletePlantVariety(data) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.plantVariety
            .deletePlantVariety({ id: data.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getPlantVarietyData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    handlePageChange(val) {
      this.spinning = true;
      this.currentPage = val;
      this.getPlantVarietyData();
    },
    handleSizeChange(val) {
      this.spinning = true;
      this.pageSize = val;
      this.getPlantVarietyData();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
